import { isPhoneExist } from '@/api/user'
export const validateCodePhone = (rule, value) => {
  const reg = /^1[3-9]\d{9}$/
  if (!value) {
    return new Error('请输入手机号')
  } else if (!reg.test(value.trim())) {
    return new Error('手机号格式不正确')
  }
  return new Promise((resolve, reject) => {
    isPhoneExist({ phone: value.trim() }).then((res) => {
      if (res.data.exist) {
        resolve()
      } else {
        reject(new Error('手机号不存在，请先注册'))
      }
    })
  })
}
export const validateRegPhone = (rule, value) => {
  const reg = /^1[3-9]\d{9}$/
  if (!value) {
    return new Error('请输入手机号')
  } else if (!reg.test(value.trim())) {
    return new Error('手机号格式不正确')
  }
  return new Promise((resolve, reject) => {
    isPhoneExist({ phone: value.trim() }).then((res) => {
      if (res.data.exist) {
        reject(new Error('手机号已存在，请直接登录'))
      } else {
        resolve()
      }
    })
  })
}
export const validateLoginPhone = (rule, value) => {
  const reg = /^1[3-9]\d{9}$/
  if (!value) {
    return new Error('请输入手机号')
  } else if (!reg.test(value.trim())) {
    return new Error('手机号格式不正确')
  }
  return true
}
export const validateName = (rule, value) => {
  const reg = /^[a-zA-Z0-9_-]{4,16}$/
  if (!value) {
    return new Error('请输入用户名，格式：4-16位字母或数字')
  } else if (!reg.test(value)) {
    return new Error('格式不正确，只能是4-16位数字和英文字母')
  }
  return true
}

export const validatePhoneCode = (rule, value) => {
  const reg = /^\d{6}$/
  if (!value) {
    return new Error('请输入6位手机验证码')
  } else if (!reg.test(value.trim())) {
    return new Error('验证码必须是6位数字')
  }
  return true
}

export const validatePassword = (rule, value) => {
  if (!value) {
    return new Error('请输入密码')
  }
  if (value.length < 6) {
    return new Error('请输入6位密码')
  }
}
