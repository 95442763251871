import moment from 'moment'
const getters = {
  token: (state) => state.user.token,
  activeNav: (state) => state.app.activeNav,
  expand: (state) => state.app.expand,
  userInfo: (state) => state.user.userInfo,
  hasUserInfo: (state) => {
    return JSON.stringify(state.user.userInfo) !== '{}'
  },
  selectedKey: (state) => state.user.selectedKey,
  // 是否是专栏会员
  isBookVIP: (state) => {
    const vip_list = state.user.userInfo.vip_list
    // 找到返回true，找不到返回false
    return vip_list.some((item) => item.Vip.name === 'VIP专栏会员')
  },
  // 专栏会员是否过期
  isBookVIPExpired: (state) => {
    const currentTime = Date.now()
    // 没有找到超级VIP的信息，说明没有超级VIP
    const bookVip = state.user.userInfo.vip_list.find(
      (item) => item.Vip.name === 'VIP专栏会员'
    )
    if (bookVip) {
      const expiration = moment(bookVip.expiration).valueOf()
      return currentTime > expiration
    }
    return true
  },
  // 是否是视频库VIP会员
  isVideoVIP: (state) => {
    const vip_list = state.user.userInfo.vip_list
    // 找到返回true，找不到返回false
    return vip_list.some((item) => item.Vip.name === '视频库VIP')
  },
  // 视频库VIP会员是否过期
  isVideoVIPExpired: (state) => {
    const currentTime = Date.now()
    // 没有找到超级VIP的信息，说明没有超级VIP
    const videoVip = state.user.userInfo.vip_list.find(
      (item) => item.Vip.name === '视频库VIP'
    )
    if (videoVip) {
      const expiration = moment(videoVip.expiration).valueOf()
      return currentTime > expiration
    }
    return true
  },
  // 是否是VIP项目会员
  isProjectVIP: (state) => {
    const vip_list = state.user.userInfo.vip_list
    // 找到返回true，找不到返回false
    return vip_list.some((item) => item.Vip.name === '训练库VIP')
  },
  // 项目会员是否过期
  isProjectVIPExpired: (state) => {
    const vipInfo = state.user.userInfo.vip_list.find(
      (item) => item.Vip.name === '训练库VIP'
    )
    if (vipInfo) {
      const expiration = moment(vipInfo.expiration).valueOf()
      return Date.now() > expiration
    }
    return true
  }
}
export default getters
