import axios from 'axios'
import store from '@/store'

import router from '@/router'

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})
// const reRequest = (err) => {
//   let back = new Promise(function (resolve) {
//     /* eslint-disable */
//     console.error('接口' + err.config.url + '请求失败，重新请求')
//     resolve()
//   })
//   return back.then(function () {
//     return request(err.config)
//   })
// }

// 请求拦截器
request.interceptors.request.use((config) => {
  // 判断token是否存在
  if (store.getters.token) {
    // 如果token存在 请求的时候带上token
    config.headers.Authorization = 'Bearer ' + store.getters.token
  }
  // 必须返回 config
  return config
})

request.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    if (err.response.status == 401) {
      store.dispatch('user/logout')
      router.push('/home')
    }
    return Promise.reject(err)
  }
)
export default request
