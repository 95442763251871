// import layout from '@/layout'
import NotFooterLayout from '@/layout/NotFooter.vue'
export const privateRoutes = [
  {
    path: '/',
    component: NotFooterLayout,
    redirect: '/learn-route',
    children: [
      {
        path: '/user',
        component: () => import('@/views/user'),
        name: 'user',
        redirect: '/user/info',
        meta: {
          title: '用户中心'
        },
        children: [
          {
            path: '/user/info',
            component: () => import('@/views/user/info.vue'),
            name: 'user-info',
            meta: {
              title: '个人信息'
            }
          },
          {
            path: '/user/course',
            component: () => import('@/views/user/course.vue'),
            name: 'user-course',
            meta: {
              title: '我的课程'
            }
          },
          {
            path: '/user/setting',
            component: () => import('@/views/user/setting.vue'),
            name: 'user-setting',
            meta: {
              title: '个人设置'
            }
          },
          {
            path: '/user/vip',
            component: () => import('@/views/user/vip.vue'),
            name: 'user-vip',
            meta: {
              title: '我的会员'
            }
          }
        ]
      }
    ]
  }
]
