import request from '@/utils/request'

// 密码登录的请求接口
export const login = (data) => {
  return request({
    url: '/auth/login/using-password',
    method: 'POST',
    data
  })
}
// 手机号登录
export const loginByPhone = (data) => {
  return request({
    url: '/auth/login/using-phone',
    method: 'POST',
    data
  })
}
// 注册的请求接口
export const registerByPhone = (data) => {
  return request({
    url: '/auth/signup/using-phone',
    method: 'POST',
    data
  })
}
// 获取验证码
export const getCaptcha = (data) => {
  return request({
    url: '/auth/verify-codes/captcha',
    method: 'POST',
    data
  })
}
// 刷新token
export const getRefreshToken = () => {
  return request({
    url: '/auth/login/refresh-token',
    method: 'POST'
  })
}

// 获取手机验证码
export const getPhoneCode = (data) => {
  return request({
    url: '/auth/verify-codes/phone',
    method: 'POST',
    data
  })
}
// 获取当前user
export const getCurrentUser = () => {
  return request({
    url: '/users/userinfo',
    method: 'GET'
  })
}

export const updateAvatar = (data) => {
  return request({
    url: '/users/avatar',
    method: 'PUT',
    data
  })
}
export const uploadAvatar = (data, headers) => {
  return request({
    url: '/users/upload-avatar',
    method: 'POST',
    data,
    headers
  })
}

export const updateUser = (data) => {
  return request({
    url: '/users',
    method: 'PUT',
    data
  })
}

export const updatePhone = (data) => {
  return request({
    url: '/users/phone',
    method: 'PUT',
    data
  })
}
export const updatePassword = (data) => {
  return request({
    url: '/users/password',
    method: 'PUT',
    data
  })
}

export const getUserProject = (data) => {
  return request({
    url: '/users/project/' + data.user_id,
    method: 'GET'
  })
}

// 手机号是否存在
export const isPhoneExist = (data) => {
  return request({
    url: '/auth/signup/phone/exist',
    method: 'POST',
    data
  })
}
