import storage from '@/utils/storage'
export default {
  namespaced: true,
  state: () => ({
    expand: false,
    activeNav: storage.getItem('activeNav') || '/home'
  }),
  mutations: {
    setExpand(state, flag) {
      state.expand = flag
    },
    setActiveNav(state, nav) {
      state.activeNav = nav
      storage.setItem('activeNav', nav)
    }
  }
}
