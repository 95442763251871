export default [
  {
    title: '首页',
    href: '/home'
  },
  {
    title: '价格',
    href: '/price'
  },
  {
    title: '体系',
    href: '/path'
  },
  // {
  //   title: '专栏',
  //   href: '/book-class'
  // },
  {
    title: '训练',
    href: '/project'
  },
  {
    title: '视频',
    href: '/video-class'
  }

  // {
  //   title: '更新日志',
  //   href: 'https://itniuma.com/article/course/',
  //   target: '_blank'
  // }
  // {
  //   title: '就业案例',
  //   href: 'https://nodeing.com/article/anli/'
  // },
  // {
  //   title: '文章',
  //   href: 'https://nodeing.com/article/'
  // }
]
